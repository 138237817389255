import { IDataDialog } from "@/components/global/info_dialog/InfoDialogController";

export type ErrorProps = Omit<IDataDialog, "open">;

export class FeedbackMessages {
  static refusedProposalMessage(
    showHelpMessage: boolean,
    topMessage?: string,
    customError?: { title: string; message: string }
  ): ErrorProps {
    const helpMessage = showHelpMessage
      ? ' Em caso de dúvidas, clique no botão "pedir ajuda" para falar com a nossa equipe.'
      : "";

    let { title, message } = customError ?? {};
    title ??= "Oops, essa proposta foi recusada.";
    message ??=
      `${
        topMessage ? `${topMessage}<br/><br/>` : ""
      }Crie ou escolha outra proposta para prosseguir.` + helpMessage;

    return {
      type: "error",
      title,
      message,
    };
  }

  static unexpectedErrorMessage(
    showHelpMessage: boolean,
    object?: Partial<ErrorProps>
  ): ErrorProps {
    const helpMessage = showHelpMessage
      ? ' Clique no botão "pedir ajuda" para entrar em contato com o nosso suporte ou tente novamente em alguns instantes.'
      : "";

    return {
      type: object?.type || "error",
      title: object?.title || "Oops, algo inesperado aconteceu!",
      message: object?.message || "Não foi possível continuar." + helpMessage,
    };
  }

  static unexpectedNextProposal(showHelpMessage: boolean): ErrorProps {
    const helpMessage = showHelpMessage
      ? ' ou acione a opção de pedir "pedir ajuda" para falar diretamente com nossa equipe.'
      : "";
    return {
      type: "error",
      title: "Oops, tivemos um problema ao prosseguir com sua proposta!",
      message: "Não conseguimos continuar com sua proposta, tente novamente" + helpMessage,
    };
  }

  static unexpectedRecoveryPassword(showHelpMessage: boolean): ErrorProps {
    const helpMessage = showHelpMessage
      ? ' Você pode clicar no botão "pedir ajuda" para falar com a nossa equipe.'
      : "";
    return {
      type: "error",
      title: "Recuperação de senha",
      message:
        `Não conseguimos validar o código, procure por uma mensagem do tipo: <strong>seu código de recuperação de senha é: ######.</strong>` +
        helpMessage,
    };
  }

  static unexpectedNewDevice(showHelpMessage: boolean): ErrorProps {
    const helpMessage = showHelpMessage
      ? ' Você pode clicar no botão "pedir ajuda" para falar com a nossa equipe.'
      : "";
    return {
      type: "error",
      title: "Cadastro de novo dispositivo",
      message:
        `Não conseguimos validar o código, procure por uma mensagem do tipo: <strong>seu código de autorização de novo dispositivo é: ######.</strong>` +
        helpMessage,
    };
  }

  static unexpectedSendMessage(showHelpMessage: boolean): ErrorProps {
    const helpMessage = showHelpMessage
      ? ' Se o problema persistir, clique no botão "pedir ajuda" para entrar em contato com nosso suporte.'
      : "";

    return {
      type: "error",
      title: "Envio do código",
      message: "Não foi possível reenviar o código. Tente novamente em 30 segundos." + helpMessage,
    };
  }

  static expiredSession(): ErrorProps {
    return {
      type: "error",
      title: "Sessão expirada",
      message: "Realize o login novamente para acessar a plataforma.",
    };
  }

  static serverError(showHelpMessage: boolean): ErrorProps {
    const helpMessage = showHelpMessage
      ? ' ou clique no botão "pedir ajuda" para entrar em contato com o nosso suporte.'
      : "";

    return {
      type: "error",
      title: "Ops, algo inesperado aconteceu!",
      message: "Tente novamente em alguns instantes" + helpMessage,
    };
  }

  static permissionDenied(message: string): ErrorProps {
    return {
      type: "error",
      title: "Você não tem permissão para essa ação!",
      message,
    };
  }

  static dynamicFeedback({ title, message }: { title: string; message: string }): ErrorProps {
    return {
      type: "error",
      title,
      message,
    };
  }
}
